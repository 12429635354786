import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Profile } from 'client';
import { createStructuredSelector } from 'reselect';

import store from '../../redux/store';
import setAuthToken from '../../redux/setAuthToken';
import { loginUser, logoutUser } from '../../redux/actions/userActions';
import { isAuthenticatedSelector } from '../../redux/selectors';

const PrivateRoute = ({
  component: Component,
  isAuth,
  getProfile,
  ...rest
}) => {
  let auth;

  auth = localStorage.getItem('w8forit');
  if (auth) {
    setAuthToken(auth);
    Profile.getProfile()
      .then(profile => store.dispatch(loginUser(profile)))
      .catch(err => {
        store.dispatch(logoutUser());
        alert(err);
      });
    isAuth = createStructuredSelector({ isAuthenticatedSelector });
  }

  return (
    <Route
      {...rest}
      render={props => {
        return isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login-page',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
