import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { transformDate, showAvatar } from '../../helpers';
import DeleteAdminDialog from '../../components/Dialog/DeleteAdminDialog';
import MaterialTable from 'material-table';
import { UsersRoutes, LessonRoutes } from '../../client';

const tableOptions = {
  actionsColumnIndex: -1,
  pageSize: 10,
  pageSizeOptions: [10, 20, 50, 100],
  padding: 'dense',
  draggable: false,
};

const LessonList = ({ lessons, isLoading }) => {
  const history = useHistory();
  return (
    <MaterialTable
      title={''}
      columns={[
        { title: 'Creator', field: 'creator.displayName' },
        { title: 'Type of class', field: 'type' },
        { title: 'Price', field: 'price' },
        { title: 'Students', field: 'usersCount' },
        {
          title: 'Create Date',
          field: 'createdAt',
          type: 'date',
          defaultSort: 'desc',
        },
        { title: 'Start Date', field: 'datetime', type: 'date' },
      ]}
      data={lessons}
      isLoading={isLoading}
      actions={[
        {
          icon: 'edit',
          tooltip: 'Edit lesson',
          onClick: (event, rowData) =>
            history.push({
              pathname: `/admin/lessons/${rowData._id}`,
              state: rowData,
            }),
        },
      ]}
      options={tableOptions}
    />
  );
};

const UsersList = ({ users, isLoading }) => {
  const history = useHistory();
  const customers = users.filter(user => user.role !== 'ADMIN');
  return (
    <MaterialTable
      title={''}
      columns={[
        { title: 'Name', field: 'displayName' },
        { title: 'Email', field: 'email' },
        { title: 'Phone', field: 'phone' },
        { title: 'Role', field: 'type' },
        {
          title: 'Created Date',
          field: 'createdAt',
          type: 'date',
          defaultSort: 'desc',
        },
        { title: 'Last Activity', field: 'lastActivity', type: 'date' },
      ]}
      data={customers}
      isLoading={isLoading}
      actions={[
        {
          icon: 'edit',
          tooltip: 'User details',
          onClick: (event, rowData) =>
            history.push({
              pathname: `/admin/users/${rowData._id}`,
              state: rowData,
            }),
        },
      ]}
      options={tableOptions}
    />
  );
};

const LessonStudents = ({ users, isLoading, id }) => {
  const [dialog, setDialog] = useState(false);
  const [pick, setPick] = useState();
  const message = 'Do you want to remove this user from lesson?';
  const action = 'remove';
  const item = 'user';

  const openDialog = () => setDialog(true);

  const closeDialog = () => setDialog(false);

  const handleRemoveUser = async () => {
    try {
      const response = await LessonRoutes.removeUser(pick);
      closeDialog();
      users = response.users;
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <MaterialTable
        title='Students'
        columns={[
          {
            title: 'Avatar',
            field: 'avatar',
            render: rowData =>
              rowData && (
                <img
                  src={showAvatar(rowData)}
                  style={{ width: 50, borderRadius: '50%' }}
                  alt={rowData.displayName}
                />
              ),
          },
          {
            title: 'Name',
            field: 'displayName',
          },
        ]}
        data={users}
        isLoading={isLoading}
        actions={[
          {
            icon: 'delete',
            tooltip: 'Remove user from lesson',
            onClick: (event, rowData) => {
              const data = { userId: rowData._id, lessonId: id };
              setPick(data);
              openDialog();
            },
          },
        ]}
        options={tableOptions}
      />
      <DeleteAdminDialog
        message={message}
        item={item}
        deleteAdminDialogOpen={dialog}
        action={action}
        onCloseDeleteAdminDialog={closeDialog}
        handleDeleteUser={handleRemoveUser}
      />
    </>
  );
};

const List = ({ pathname, users, lessons, id }) => {
  const [someUsers, setUsers] = useState([]);
  const [someLessons, setLessons] = useState([]);
  const [path, setPath] = useState('');
  const [isLoading, setLoading] = useState(true);
  const transformLesson = lesson => ({
    ...lesson,
    usersCount: lesson.users.length,
  });

  const history = useHistory();

  useEffect(() => {
    if (pathname === '/admin/users' || (users && users.length > 0)) {
      if (users && users.length !== someUsers.length) {
        const withDate = users.map(transformDate);
        setUsers(withDate);
        setPath('users');
        setLoading(false);
      }

      if (users.length === 0 && history.action === 'PUSH') {
        setPath('users');
        setLoading(false);
      }

      if (users.length === 0 && history.action === 'POP') {
        UsersRoutes.getUsers()
          .then(result => {
            if (result.students.length > 0 || result.trainers.length > 0) {
              const users = [...result.students, ...result.trainers];
              const withDate = users.map(transformDate);
              setUsers(withDate);
            }

            setPath('users');
          })
          .then(() => setLoading(false))
          .catch(err => {
            alert(err);
            setLoading(false);
          });
      }
    }

    if (pathname === '/admin/lessons') {
      if (lessons && lessons.length !== someLessons.length) {
        const transform = lessons.map(transformLesson);
        const withDate = transform.map(transformDate);
        setLessons(withDate);
        setPath('lessons');
        setLoading(false);
      }

      if (lessons.length === 0 && history.action === 'PUSH') {
        setPath('lessons');
        setLoading(false);
      }

      if (lessons.length === 0 && history.action === 'POP') {
        LessonRoutes.getLessons()
          .then(result => {
            if (result.lessons.length > 0) {
              const withDate = result.lessons.map(transformDate);
              setUsers(withDate);
            }
            setPath('lessons');
          })
          .then(() => setLoading(false))
          .catch(err => {
            alert(err);
            setLoading(false);
          });
      }
    }

    if (typeof pathname === 'undefined') {
      if (users && users.length !== someUsers.length) {
        const withDate = users.map(transformDate);
        setUsers(withDate);
        setPath('students');
        setLoading(false);
      } else if (users.length === 0 && someUsers.length === 0) {
        setPath('students');
        setLoading(false);
      }
    }
  }, [someUsers, someLessons, path]);

  return path === 'users' ? (
    <UsersList users={someUsers} isLoading={isLoading} />
  ) : path === 'lessons' ? (
    <LessonList lessons={someLessons} isLoading={isLoading} />
  ) : (
    <LessonStudents users={someUsers} isLoading={isLoading} id={id} />
  );
};

List.propTypes = {
  pathname: PropTypes.string,
  users: PropTypes.array,
  lessons: PropTypes.array,
};
export default List;
