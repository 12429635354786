import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { showAvatar } from '../../helpers';
import List from '../../components/List/List';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardAvatar from 'components/Card/CardAvatar.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Maps from '../Maps/Maps';

import DeleteAdminDialog from '../../components/Dialog/DeleteAdminDialog';

import { LessonRoutes } from '../../client';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    // fontStyle: 'italic',
  },
  cardCategory: {
    textTransform: 'uppercase',
    margin: '16px 0 8px',
    fontWeight: '600',
  },
  description: {
    textTransform: 'uppercase',
    margin: '16px 0 8px',
  },
  socialCat: {
    firstLetter: {
      textTransform: 'capitalize',
    },
    margin: '8px 0 4px',
  },
  social: {
    margin: '8px 0 4px',
  },
};

const LessonDetails = props => {
  const [currentLesson, setLesson] = useState({});
  const [dialog, setDialog] = useState(false);
  const message = 'Do you want to delete this lesson?';
  const action = 'delete';
  const item = 'lesson';

  const { classes } = props;
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    if (
      props.location.state &&
      currentLesson._id !== props.location.state._id
    ) {
      setLesson(props.location.state);
    }
    if (props.history.action === 'POP' && !currentLesson._id) {
      LessonRoutes.getLesson(params.id).then(lesson =>
        setLesson(lesson.requestedLesson),
      );
    }
  }, [currentLesson]);

  const openDialog = () => setDialog(true);
  const closeDialog = () => setDialog(false);
  const handleDeleteLesson = async () => {
    try {
      await LessonRoutes.deleteLesson(currentLesson);
      history.replace('/admin/lessons');
    } catch (error) {
      alert(error);
    }
  };

  const {
    location,
    peopleNumber,
    trainer,
    creator,
    datetime,
    type,
    price,
    description,
    users,
    createdAt,
    updatedAt,
    usersCount,
  } = currentLesson;

  return (
    <>
      <DeleteAdminDialog
        message={message}
        item={item}
        deleteAdminDialogOpen={dialog}
        action={action}
        onCloseDeleteAdminDialog={closeDialog}
        handleDeleteUser={handleDeleteLesson}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color='primary'>
              <GridContainer>
                <GridItem xs={12} sm={8} md={8}>
                  <h4 className={classes.cardTitleWhite}>{type}</h4>
                </GridItem>
                <GridItem xs={10} sm={3} md={3}>
                  <Button onClick={openDialog} type='submit' color='danger'>
                    Delete Lesson
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <p className={classes.cardCategory}>Calendar</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.socialCat}>Creation Date</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.socialCat}>{createdAt}</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.socialCat}>Change/update Date</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.socialCat}>{updatedAt}</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.socialCat}>Start Date</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.socialCat}>{datetime}</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.cardCategory}>Price</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.description}>{price} $</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p className={classes.cardCategory}>Students info</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.socialCat}>
                        Limit number of students
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.socialCat}>{peopleNumber}</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.socialCat}>Registered users</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.socialCat}>{usersCount}</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.socialCat}>
                        Available places at lesson
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p className={classes.socialCat}>
                        {String(peopleNumber - usersCount)}
                      </p>
                    </GridItem>
                    {description && (
                      <>
                        <GridItem xs={12} sm={12} md={12}>
                          <p className={classes.cardCategory}>
                            Lesson description
                          </p>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <p className={classes.socialCat}>{description}</p>
                        </GridItem>
                      </>
                    )}
                    <GridItem xs={12} sm={12} md={12}>
                      {users && <List users={users} id={currentLesson._id} />}
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  {location && <Maps coordinates={location.coordinates}></Maps>}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <GridContainer>
            <GridItem xs={12}>
              {creator && (
                <Card profile>
                  <CardAvatar profile>
                    <a href='#link' onClick={e => e.preventDefault()}>
                      <img
                        src={showAvatar(creator)}
                        alt={creator.displayName}
                      />
                    </a>
                  </CardAvatar>
                  <CardBody profile>
                    <h6 className={classes.cardCategory}>Creator</h6>
                    <h4 className={classes.cardTitle}>{creator.displayName}</h4>
                    <p className={classes.description}>{creator.email}</p>
                    <p className={classes.description}>{creator.phone}</p>
                  </CardBody>
                </Card>
              )}
            </GridItem>
            <GridItem xs={12}>
              {trainer && (
                <Card profile>
                  <CardAvatar profile>
                    <a href='#link' onClick={e => e.preventDefault()}>
                      <img
                        src={showAvatar(trainer)}
                        alt={trainer.displayName}
                      />
                    </a>
                  </CardAvatar>
                  <CardBody profile>
                    <h6 className={classes.cardCategory}>Trainer</h6>
                    <h4 className={classes.cardTitle}>{trainer.displayName}</h4>
                    <p className={classes.description}>{trainer.email}</p>
                    <p className={classes.description}>{trainer.phone}</p>
                  </CardBody>
                </Card>
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  );
};

LessonDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LessonDetails);
