import * as students from '../reducers/usersReducer';
import * as lessons from '../reducers/lessonsReducer';
const { GET_USERS } = students.Type;
const { GET_LESSONS } = lessons.Type;

export const setUsers = payload => ({
  type: GET_USERS,
  payload,
});

export const setLessons = payload => ({
  type: GET_LESSONS,
  payload,
});

export const getUsers = users => async dispatch => {
  try {
    dispatch(setUsers(users));
  } catch (error) {
    return error;
  }
};

export const getLessons = lessons => async dispatch => {
  try {
    dispatch(setLessons(lessons));
  } catch (error) {
    return error;
  }
};
