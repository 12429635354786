import api from './api';
import unify from './api/unify';

export const getUsers = () => unify(api.get('/users'));

export const getUser = _id => unify(api.get(`/users/${_id}`));

export const setAdmin = _id => unify(api.patch(`/users/admin`, { _id }));

export const blockUser = _id => unify(api.patch(`/users/block`, { _id }));

export const unblockUser = _id => unify(api.patch(`/users/unblock`, { _id }));

export const deleteUser = user => unify(api.delete(`/users/${user._id}`));

export const updateUser = data => unify(api.put(`/users/${data._id}`, data));
