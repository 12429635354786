import api from './api';
import unify from './api/unify';

export const getLessons = () => unify(api.get('/lessons'));

export const getLesson = id => unify(api.get(`/lessons/${id}`));

export const deleteLesson = lesson =>
  unify(api.delete(`/lessons/${lesson._id}`));

export const removeUser = data =>
  unify(api.patch(`/lessons/${data.lessonId}`, data));
