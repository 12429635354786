import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AuthRoutes } from '../../client';
import { loginUser, logoutUser } from '../../redux/actions/userActions';
import setAuthToken from '../../redux/setAuthToken';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

// @material-ui/icons
import Email from '@material-ui/icons/Email';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

import Snackbar from 'components/Snackbar/Snackbar.jsx';

import loginPageStyle from 'assets/jss/material-dashboard-react/views/loginPageStyle.jsx';

const LoginPage = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [tc, setAlert] = useState(false);
  const [message, setMessage] = useState('');

  const errors = {};

  const inputChange = ({ target }) => {
    const { id, value } = target;
    id === 'email' ? setEmail(value) : setPassword(value);
  };

  const showNotification = place => {
    setAlert(true);
    setTimeout(function () {
      setAlert(false);
    }, 3000);
  };
  const handleLogin = async e => {
    e.preventDefault();
    const user = { email, password };
    const { loginUser, logoutUser, history } = props;
    try {
      const admin = await AuthRoutes.login(user);
      if (admin.role === 'ADMIN') {
        loginUser(admin);
        localStorage.setItem('w8forit', admin.token);
        setAuthToken(admin.token);
        history.push('/');
      } else {
        throw new Error('You got no permission to Login this service.');
      }
    } catch (error) {
      logoutUser();
      setMessage(error.description || error.message);
      showNotification('tc');
    }
  };
  const { classes } = props;
  return (
    <div className={classes.container}>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={8}>
          <h4 className={classes.textCenter} style={{ marginTop: 0 }}>
            Log in to see your admin panel of Application
          </h4>
        </GridItem>
      </GridContainer>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleLogin}>
            <Card>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color='primary'
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  handleChange={inputChange}
                  labelText='Email...'
                  id='email'
                  error={errors.username || errors.invalidEmailOrPassword}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName,
                  }}
                  inputProps={{
                    required: true,
                    name: 'email',
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  handleChange={inputChange}
                  labelText='Password'
                  id='password'
                  error={errors.password || errors.invalidEmailOrPassword}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName,
                  }}
                  inputProps={{
                    type: 'password',
                    required: true,
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type='submit' color='primary' simple size='lg' block>
                  Let's Go
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
        <Snackbar
          place='tc'
          color='danger'
          icon={ErrorOutline}
          message={message}
          open={tc}
          closeNotification={() => setAlert(false)}
          close
        />
      </GridContainer>
    </div>
  );
};

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  errors: PropTypes.object,
};

const mapDispatchToProps = {
  loginUser,
  logoutUser,
};

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(loginPageStyle)(LoginPage));
