// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Login from '@material-ui/icons/LockOpen';
// core components/views for Admin layout
import DashboardPage from 'views/Dashboard';
import UserProfile from 'views/UserProfile/UserProfile.jsx';
import LessonProfile from 'views/LessonProfile/LessonProfile.jsx';
import TableList from 'views/TableList/TableList.jsx';
// core components/views for Auth layout
import LoginPage from 'views/Login/LoginPage.jsx';

const dashboardRoutes = [
  {
    path: '/users/:id',
    name: 'User profile',
    icon: Person,
    component: UserProfile,
    layout: '/admin',
  },
  {
    path: '/lessons/:id',
    name: 'Lesson details',
    icon: Person,
    component: LessonProfile,
    layout: '/admin',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin',
  },
  {
    path: '/user-profile',
    name: 'My Profile',
    icon: Person,
    component: UserProfile,
    layout: '/admin',
  },
  {
    path: '/users',
    name: 'Users List',
    icon: 'table_chart_rounded',
    component: TableList,
    layout: '/admin',
  },
  {
    path: '/lessons',
    name: 'Lessons List',
    icon: LibraryBooks,
    component: TableList,
    layout: '/admin',
  },
  {
    path: '/login-page',
    name: 'Login Page',
    icon: Login,
    component: LoginPage,
    layout: '/auth',
  },
  {
    path: '/logout',
    name: 'LOGOUT',
    icon: 'logout',
    layout: '/admin',
  },
];

export default dashboardRoutes;
