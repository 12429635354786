import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import List from 'components/List/List.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

function TableList (props) {
  const { classes, users, lessons } = props;
  const { pathname } = props.location;
  const tableName =
    pathname === '/admin/users' ? 'Users Table' : 'Lessons Table';
  const subTitle =
    pathname === '/admin/users'
      ? 'Here is the list of all users, registered at Yoga Buddy application'
      : 'Here is the list of created lessons';
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='info'>
            <h4 className={classes.cardTitleWhite}>{tableName}</h4>
            <p className={classes.cardCategoryWhite}>{subTitle}</p>
          </CardHeader>
          <CardBody>
            <List users={users} pathname={pathname} lessons={lessons} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = state => ({
  users: [...state.users.students, ...state.users.trainers],
  lessons: state.lessons.lessons,
});

export default connect(mapStateToProps)(withStyles(styles)(TableList));
