import React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';

// core components
import Admin from './Admin.jsx';
import Auth from './Auth.jsx';

const hist = createBrowserHistory();

const App = () => {
  return (
    <Router history={hist}>
      <Switch>
        <PrivateRoute path='/admin' component={Admin} />
        <Route path='/auth' component={Auth} />
        <Redirect from='/' to='/admin/dashboard' />
      </Switch>
    </Router>
  );
};

export default App;
