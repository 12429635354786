import PrivateRoute from './PrivateRoute';
import { connect } from 'react-redux';
import { loginUser, logoutUser } from '../../redux/actions/userActions';
const mapStateToProps = ({ isAuth }) => ({
  isAuth,
});

const mapDispatchToProps = {
  loginUser,
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
