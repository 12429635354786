import Dashboard from './Dashboard';
import { connect } from 'react-redux';
import { getUsers, getLessons } from '../../redux/actions/dashboardActions';

import {
  studentsCount,
  trainersCount,
  lessonsCount,
} from '../../redux/selectors';

const mapStateToProps = state => ({
  studentsCount: studentsCount(state),
  trainersCount: trainersCount(state),
  lessonsCount: lessonsCount(state),
});

const mapDispatchToProps = {
  getUsers,
  getLessons,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
