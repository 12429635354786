export const Type = {
  GET_USERS: 'GET_USERS',
};

const initialState = {
  students: [],
  trainers: [],
  studentsCount: 0,
  trainersCount: 0,
};

const adminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Type.GET_USERS:
      return payload;

    default:
      return state;
  }
};

export default adminReducer;
