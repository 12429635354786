import { Type } from '../reducers/adminReducer';
// import { login, profile } from '../api/fetch';
import { userExist, userLogOut } from './authActions';
import setAuthToken from '../setAuthToken';

export const setAdmin = user => ({
  type: Type.LOGIN_SUCCESS,
  payload: user,
});

export const loginUser = user => async dispatch => {
  try {
    await dispatch(setAdmin(user));
    await dispatch(userExist());
  } catch (error) {
    alert(error);
  }
};

export const logoutUser = () => dispatch => {
  localStorage.removeItem('token');
  setAuthToken(false);
  dispatch(userLogOut());
};
