export const Type = {
  GET_LESSONS: 'GET_LESSONS',
};

const initialState = {
  lessons: [],
  count: 0,
};

const adminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Type.GET_LESSONS:
      return payload;

    default:
      return state;
  }
};

export default adminReducer;
