import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

const CustomSkinMap = withScriptjs(
  withGoogleMap(props => {
    // const [latLong, setLatLong] = useState([]);
    // let lat, lng;
    // useEffect(() => {
    //   if (props.coordinates) {
    //     setLatLong(props.coordinates);
    //     lat = latLong[0];
    //     lng = latLong[1];
    //   }
    // }, [lat]);
    return (
      <GoogleMap
        defaultZoom={13}
        defaultCenter={{ lat: props.coordinates[0], lng: props.coordinates[1] }}
        defaultOptions={{
          scrollwheel: false,
          zoomControl: true,
          styles: [
            {
              featureType: 'water',
              stylers: [
                { saturation: 43 },
                { lightness: -11 },
                { hue: '#0088ff' },
              ],
            },
            {
              featureType: 'road',
              elementType: 'geometry.fill',
              stylers: [
                { hue: '#ff0000' },
                { saturation: -100 },
                { lightness: 99 },
              ],
            },
            {
              featureType: 'road',
              elementType: 'geometry.stroke',
              stylers: [{ color: '#808080' }, { lightness: 54 }],
            },
            {
              featureType: 'landscape.man_made',
              elementType: 'geometry.fill',
              stylers: [{ color: '#ece2d9' }],
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry.fill',
              stylers: [{ color: '#ccdca1' }],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#767676' }],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.stroke',
              stylers: [{ color: '#ffffff' }],
            },
            { featureType: 'poi', stylers: [{ visibility: 'off' }] },
            {
              featureType: 'landscape.natural',
              elementType: 'geometry.fill',
              stylers: [{ visibility: 'on' }, { color: '#b8cb93' }],
            },
            { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
            {
              featureType: 'poi.sports_complex',
              stylers: [{ visibility: 'on' }],
            },
            { featureType: 'poi.medical', stylers: [{ visibility: 'on' }] },
            {
              featureType: 'poi.business',
              stylers: [{ visibility: 'simplified' }],
            },
          ],
        }}
      >
        <Marker
          position={{ lat: props.coordinates[0], lng: props.coordinates[1] }}
        />
      </GoogleMap>
    );
  }),
);

const Maps = ({ coordinates }) => {
  return (
    <CustomSkinMap
      googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyAA3Ka_9Jhnncf7mTly2AnJC8VT95MIz_8'
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      coordinates={coordinates}
    />
  );
};

export default Maps;
