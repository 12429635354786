export const Type = {
  USER_EXIST: 'USER_EXIST',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = false;

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Type.USER_EXIST:
      return true;

    case Type.USER_LOGOUT:
      return false;

    default:
      return state;
  }
};

export default authReducer;
