import moment from 'moment';
import defaultAvatar from 'assets/img/faces/avatar.png';

export const transformDate = array => ({
  ...array,
  createdAt: moment(array.createdAt).format('DD-MM-YYYY HH:mm:ss'),
  updatedAt: moment(array.updatedAt).format('DD-MM-YYYY HH:mm:ss'),
  lastActivity: array.lastActivity
    ? moment(array.lastActivity).format('DD-MM-YYYY HH:mm:ss')
    : null,
  trialEnds: array.trialEnds
    ? moment(array.trialEnds).format('DD-MM-YYYY HH:mm:ss')
    : null,
  planExpired: array.planExpired
    ? moment(array.planExpired).format('DD-MM-YYYY HH:mm:ss')
    : null,
  datetime: array.datetime
    ? moment(array.datetime).format('DD-MM-YYYY HH:mm:ss')
    : null,
});

export const showAvatar = user => {
  const { avatar, facebook, google } = user;
  if (avatar) {
    return `https://storage.googleapis.com/yoga-avatars/${avatar}`;
  } else if (facebook) {
    return facebook.avatar;
  } else if (google) {
    return google.avatar;
  } else {
    return defaultAvatar;
  }
};
