import { combineReducers } from 'redux';
import adminReducer from './adminReducer';
import authReducer from './authReducer';
import usersReducer from './usersReducer';
import lessonsReducer from './lessonsReducer';

const rootReducer = combineReducers({
  admin: adminReducer,
  isAuth: authReducer,
  users: usersReducer,
  lessons: lessonsReducer,
});

export default rootReducer;
