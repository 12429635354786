import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import PersonRounded from '@material-ui/icons/PersonRounded';
import SpaRounded from '@material-ui/icons/SpaRounded';
import SchoolRounded from '@material-ui/icons/SchoolRounded';
// core components
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';

const Dashboard = props => {
  const { classes, studentsCount, trainersCount, lessonsCount } = props;

  return (
    <GridContainer>
      <GridItem xs={12} sm={4}>
        <Card>
          <CardHeader color='success' stats icon>
            <CardIcon color='success'>
              <SchoolRounded />
            </CardIcon>
            <p className={classes.cardCategory}>Students</p>
            <h3 className={classes.cardTitle}>
              {studentsCount} <small>users</small>
            </h3>
          </CardHeader>
          <CardFooter stats />
        </Card>
      </GridItem>
      <GridItem xs={12} sm={4}>
        <Card>
          <CardHeader color='info' stats icon>
            <CardIcon color='info'>
              <PersonRounded />
            </CardIcon>
            <p className={classes.cardCategory}>Teachers</p>
            <h3 className={classes.cardTitle}>
              {trainersCount} <small>users</small>
            </h3>
          </CardHeader>
          <CardFooter stats />
        </Card>
      </GridItem>
      <GridItem xs={12} sm={4}>
        <Card>
          <CardHeader color='primary' stats icon>
            <CardIcon color='primary'>
              <SpaRounded />
            </CardIcon>
            <p className={classes.cardCategory}>Lessons</p>
            <h3 className={classes.cardTitle}>
              {lessonsCount} <small>items</small>
            </h3>
          </CardHeader>
          <CardFooter stats />
        </Card>
      </GridItem>
    </GridContainer>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Dashboard);
