export const Type = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_ADMIN: 'LOGOUT_ADMIN',
};

const initialState = null;

const adminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Type.LOGIN_SUCCESS:
      return payload;

    case Type.LOGOUT_ADMIN:
      return initialState;
    default:
      return state;
  }
};

export default adminReducer;
