import { createSelector } from 'reselect';

export const isAuth = state => state.isAuth;

export const adminSelector = createSelector(isAuth, admin => admin);

export const isAuthenticatedSelector = createSelector(
  isAuth,
  state => state.isAuth,
);

export const students = state => {
  const { users } = state;
  return users ? users.students : [];
};

export const studentsCount = state => {
  const { users } = state;
  return users ? users.studentsCount : 0;
};

export const trainers = state => {
  const { users } = state;
  return users ? users.trainers : [];
};

export const trainersCount = state => {
  const { users } = state;
  return users ? users.trainersCount : 0;
};

export const lessons = state => {
  const { lessons } = state;
  return lessons ? lessons.lessons : [];
};

export const lessonsCount = state => {
  const { lessons } = state;
  return lessons ? lessons.count : 0;
};
